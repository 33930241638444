<template>
	<article class="d-error-page">
		<header
			:class="[
				'd-error-page__header',
				'w-full px-layout-margin bg-primary pb-md >=1024:pb-xl',
			]"
		>
			<div class=">=1024:w-6col mb-xl">
				<div class="text-h8" v-text="error.statusCode"></div>
				<BaseH1 class="mt-sm" v-text="header.title || error.message" />

				<NuxtLinkExt
					v-if="frontPageLink.node"
					:to="frontPageLink.node.url"
					:target="frontPageLink.node.target"
					class="block mt-md"
				>
					<BaseButton
						tag="div"
						class="w-fit"
						v-text="frontPageLink.title || frontPageLink.node.name"
					/>
				</NuxtLinkExt>
			</div>
		</header>

		<div class="pt-md pb-2xl">
			<BlockList :block-list="contentElements" class="w-full" />
		</div>
	</article>
</template>

<script>
import BlockList from '~/components/subsite/BlockList';

export default {
	name: 'SubsiteErrorPage',
	components: { BlockList },
	inheritAttrs: false,

	props: {
		contentElements: {
			type: Array,
			default: () => [],
		},
		header: {
			type: Object,
			default: () => ({}),
		},
		frontPageLink: {
			type: Object,
			default: () => ({}),
		},
		error: {
			type: Object,
			default: () => ({}),
		},
	},
};
</script>

<style lang="postcss">
/** Behold. Magic numbers! */
.d-error-page__header {
	padding-top: 152px;
}

@screen >=1024 {
	.d-error-page__header {
		padding-top: 256px;
	}
}
</style>
